export const CSE_CORE = {
    "name": "CSE Core",
    "courses": [
        { "name": "5G and Edge Computing" },
        { "name": "Advanced Excel" },
        { "name": "Artificial Intelligence and Expert Systems" },
        { "name": "Artificial Intelligence and Expert Systems Laboratory" },
        { "name": "Augmented Reality and Virtual Reality" },
        { "name": "Bigdata Technologies" },
        { "name": "Blockchain Technology" },
        { "name": "Calculus and Numerical Methods" },
        { "name": "Capstone Project" },
        { "name": "Cloud Computing and DevOps" },
        { "name": "Cognitive Computing and Natural Language Processing" },
        { "name": "Co-creation" },
        { "name": "Computer Graphics and 3D Modelling" },
        { "name": "Computer Networks" },
        { "name": "Computer Networks Laboratory" },
        { "name": "Computer Vision" },
        { "name": "Critical Thinking" },
        { "name": "Cyber Laws and Cyber Crime" },
        { "name": "Data Engineering and Data Visualization" },
        { "name": "Data Structures" },
        { "name": "Data Structures Laboratory" },
        { "name": "Database Management System" },
        { "name": "Database Management System Laboratory" },
        { "name": "Deep Learning" },
        { "name": "Design and Analysis of Algorithms" },
        { "name": "Digital Design Automation" },
        { "name": "Digital IC Design" },
        { "name": "Discrete Mathematics with Graph Theory" },
        { "name": "Distributed Computing" },
        { "name": "Effective Communication" },
        { "name": "Engineering Graphics" },
        { "name": "Engineering Mechanics" },
        { "name": "Engineering Physics" },
        { "name": "Environment and Sustainability" },
        { "name": "Financial Literacy" },
        { "name": "Foundations of Computer Architecture and System Design" },
        { "name": "Foundations of Peace" },
        { "name": "Foundations of Programming" },
        { "name": "Full Stack Development Laboratory" },
        { "name": "Ideas and Innovations in Manufacturing" },
        { "name": "Indian Constitution" },
        { "name": "Indian Knowledge System" },
        { "name": "Indian Knowledge System (Science & Technology)" },
        { "name": "Information and Cyber Security" },
        { "name": "Internet of Things Laboratory" },
        { "name": "Life Transformation Skills" },
        { "name": "Machine Learning" },
        { "name": "Machine Learning Laboratory" },
        { "name": "Managing Conflicts Peacefully: Tools and Techniques" },
        { "name": "Microprocessor, Microcontroller and Applications" },
        { "name": "Mini Project using Java Programming" },
        { "name": "National Academic Immersion" },
        { "name": "Object Oriented Concepts using C++" },
        { "name": "Operating System" },
        { "name": "Operating System Laboratory" },
        { "name": "Parallel Programming" },
        { "name": "Probability and Statistics" },
        { "name": "Project Based Learning – I" },
        { "name": "Project Based Learning – II" },
        { "name": "Project Based Learning – III" },
        { "name": "Project Based Learning – IV" },
        { "name": "Research Innovation Design Entrepreneurship" },
        { "name": "Rural Immersion" },
        { "name": "Seminar" },
        { "name": "Social Leadership Development Program" },
        { "name": "Software Engineering and Modelling" },
        { "name": "Soft Computing" },
        { "name": "Spiritual and Cultural Heritage: Indian Experience" },
        { "name": "Sports" },
        { "name": "System Software and Compiler Design" },
        { "name": "Theory of Computation" },
        { "name": "Unmanned Aerial Vehicle (UAV) and Drone Technology" },
        { "name": "University Elective - I" },
        { "name": "University Elective - II" },
        { "name": "University Electives - III" },
        { "name": "User Interface and User Experience Design" },
        { "name": "VLSI Design" },
        { "name": "VLSI Design Verification and Testing" },
        { "name": "Vulnerability Identification and Penetration Testing" },
        { "name": "Wireless and Mobile Networks" },
        { "name": "Yoga – I" },
        { "name": "Yoga - II" },
        { "name": "Other" }
    ]
};

export const CSE_AIDS = {
    "name": "CSE AIDS",
    "courses": [
        { "name": "AI Systems and Applications" },
        { "name": "Advanced Excel" },
        { "name": "Artificial Intelligence and Expert Systems" },
        { "name": "Artificial Intelligence and Expert Systems Laboratory" },
        { "name": "Augmented Reality and Virtual Reality" },
        { "name": "Bigdata Technologies" },
        { "name": "Bioinformatics Algorithms and Applications" },
        { "name": "Blockchain Technology" },
        { "name": "Calculus and Numerical Methods" },
        { "name": "Capstone Project" },
        { "name": "Cognitive Computing and Natural Language Processing" },
        { "name": "Cognitive Computing and Natural Language Processing Lab" },
        { "name": "Co-creation" },
        { "name": "Computer Vision" },
        { "name": "Critical Thinking" },
        { "name": "Data Engineering Concepts" },
        { "name": "Data Engineering Concepts Laboratory" },
        { "name": "Data Science Using R Programming" },
        { "name": "Data Structures" },
        { "name": "Data Structures Laboratory" },
        { "name": "Data Visualization using Python" },
        { "name": "Database Management Systems" },
        { "name": "Database Management Systems Laboratory" },
        { "name": "Deep Learning" },
        { "name": "Deep Learning Lab" },
        { "name": "Design and Analysis of Algorithm" },
        { "name": "Discrete Mathematics with Graph Theory" },
        { "name": "Distributed and Cloud Computing" },
        { "name": "Effective Communication" },
        { "name": "Edge Artificial Intelligence" },
        { "name": "Engineering Chemistry" },
        { "name": "Engineering Graphics" },
        { "name": "Engineering Mechanics" },
        { "name": "Engineering Physics" },
        { "name": "Environment and Sustainability" },
        { "name": "Financial Literacy" },
        { "name": "Foundations of Computer Architecture and System Design" },
        { "name": "Foundations of Peace" },
        { "name": "Foundations of Programming" },
        { "name": "Generative Adversarial Network" },
        { "name": "Generative AI" },
        { "name": "Graph Machine Learning" },
        { "name": "Ideas and Innovations in Manufacturing" },
        { "name": "Indian Constitution" },
        { "name": "Indian Knowledge System" },
        { "name": "Indian Knowledge System (Science & Technology)" },
        { "name": "Internship" },
        { "name": "Life Transformation Skills" },
        { "name": "Linear Algebra and Differential Calculus" },
        { "name": "Machine Learning" },
        { "name": "Machine Learning Lab" },
        { "name": "Managing Conflicts Peacefully: Tools and Techniques" },
        { "name": "Mini Project using Java Programming" },
        { "name": "National Academic Immersion" },
        { "name": "Network and Cyber Security" },
        { "name": "Operating Systems" },
        { "name": "Operating Systems Laboratory" },
        { "name": "Pattern Recognition" },
        { "name": "Probability and Statistics" },
        { "name": "Project Based Learning - II" },
        { "name": "Project Based Learning – I" },
        { "name": "Project Based Learning – III" },
        { "name": "Project Based Learning – IV" },
        { "name": "Research Innovation Design Entrepreneurship" },
        { "name": "Rural Immersion" },
        { "name": "Scalable Data Science" },
        { "name": "Seminar" },
        { "name": "Sentiment and Emotional Analysis" },
        { "name": "Social Leadership Development Program" },
        { "name": "Soft Computing" },
        { "name": "Software Engineering and Modelling" },
        { "name": "Spiritual and Cultural Heritage: Indian Experience" },
        { "name": "Sports" },
        { "name": "System Software and Compiler Design" },
        { "name": "Theory of Computation" },
        { "name": "Time Series Analysis and Forecasting" },
        { "name": "University Elective - I" },
        { "name": "University Elective - II" },
        { "name": "University Elective - III" },
        { "name": "User Interface and User Experience Design" },
        { "name": "Yoga – I" },
        { "name": "Yoga – II" },
        { "name": "Other" }
    ]
    
};

export const CSE_CSF = {
    "name": "CSE CSF",
    "courses": [
        { "name": "Advanced Excel" },
        { "name": "Application and Web Security" },
        { "name": "Artificial Intelligence and Machine Learning Techniques" },
        { "name": "Attack, Reporting and Documentation" },
        { "name": "Augmented Reality and Virtual Reality" },
        { "name": "Bigdata Technologies" },
        { "name": "Blockchain Technology Concepts and Application" },
        { "name": "Blockchain Technology Laboratory" },
        { "name": "Calculus and Numerical Methods" },
        { "name": "Capstone Project" },
        { "name": "Cloud Infrastructure and Security" },
        { "name": "Cloud Infrastructure and Security Laboratory" },
        { "name": "Co-creation" },
        { "name": "Computer Networks" },
        { "name": "Computer Networks Laboratory" },
        { "name": "Critical Thinking" },
        { "name": "Cyber Physical System Security" },
        { "name": "Cyber Threat and Intelligence Management" },
        { "name": "Database Management System" },
        { "name": "Database Management System Laboratory" },
        { "name": "Data Privacy" },
        { "name": "Data Science for Cybersecurity and Forensics" },
        { "name": "Data Structures" },
        { "name": "Data Structures Laboratory" },
        { "name": "Design and Analysis of Algorithms" },
        { "name": "Digital Forensics and Investigation" },
        { "name": "Digital Forensics and Investigation Laboratory" },
        { "name": "Discrete Mathematics with Graph Theory" },
        { "name": "Effective Communication" },
        { "name": "Embedded Systems and Internet of Things Laboratory" },
        { "name": "Enterprise Infrastructure Security" },
        { "name": "Engineering Chemistry" },
        { "name": "Engineering Graphics" },
        { "name": "Engineering Mechanics" },
        { "name": "Engineering Physics" },
        { "name": "Environment and Sustainability" },
        { "name": "Financial Literacy" },
        { "name": "Foundations of Computer Architecture and System Design" },
        { "name": "Foundations of Peace" },
        { "name": "Foundations of Programming" },
        { "name": "Full Stack Development Laboratory" },
        { "name": "High Performance Computing" },
        { "name": "Ideas and Innovations in Manufacturing" },
        { "name": "Identity and Access Management" },
        { "name": "Indian Constitution" },
        { "name": "Indian Knowledge System" },
        { "name": "Indian Knowledge System (Science & Technology)" },
        { "name": "Information and Cyber Security" },
        { "name": "Internship" },
        { "name": "Life Transformation Skills" },
        { "name": "Linear Algebra and Differential Calculus" },
        { "name": "Managing Conflicts Peacefully: Tools and Techniques" },
        { "name": "Mini Project using Java Programming" },
        { "name": "National Academic Immersion" },
        { "name": "Object Oriented Programming using C++" },
        { "name": "Operating System" },
        { "name": "Operating System Laboratory" },
        { "name": "Probability and Statistics" },
        { "name": "Project Based Learning – I" },
        { "name": "Project Based Learning – II" },
        { "name": "Project Based Learning – III" },
        { "name": "Project Based Learning – IV" },
        { "name": "Research Innovation Design Entrepreneurship (RIDE)" },
        { "name": "Risk Assessment and Security Policies" },
        { "name": "Rural Immersion" },
        { "name": "Security and Privacy in Social Media" },
        { "name": "Security Management, Cyber Laws and Ethics" },
        { "name": "Seminar" },
        { "name": "Social Leadership Development Program" },
        { "name": "Software Engineering and Project Management" },
        { "name": "Spiritual and Cultural Heritage: Indian Experience" },
        { "name": "Sports" },
        { "name": "System Software and Compiler Design" },
        { "name": "Theory of Computation" },
        { "name": "University Electives – I" },
        { "name": "University Electives – II" },
        { "name": "University Electives – III" },
        { "name": "User Interface and User Experience Design" },
        { "name": "Vulnerability Assessment and Penetration Testing" },
        { "name": "Wireless and Mobile Device Security" },
        { "name": "Yoga – I" },
        { "name": "Yoga – II" },
        { "name": "Other" }
    ]
};

    export const CSE_CSBS = {
        "name": "CSE CSBS",
        "courses": [
            { "name": "Advanced Finance" },
            { "name": "Advanced Social, Text and Media Analytics" },
            { "name": "Analysis and Design of Algorithms" },
            { "name": "Artificial Intelligence and Applications" },
            { "name": "Behavioral Economics" },
            { "name": "Business, Communication and Value Science" },
            { "name": "Business Strategy" },
            { "name": "Cloud, Microservices and Application" },
            { "name": "Cognitive Science and Analytics" },
            { "name": "Compiler Design" },
            { "name": "Computer Networking" },
            { "name": "Computer Organization and Architecture" },
            { "name": "Conversational Systems" },
            { "name": "Computational Finance and Modeling" },
            { "name": "Computational Statistics" },
            { "name": "Cryptology" },
            { "name": "Data Mining and Analytics" },
            { "name": "Data Structures 1" },
            { "name": "Data Structures 2" },
            { "name": "Design, Thinking and Innovation" },
            { "name": "Discrete Mathematics for Computer Science" },
            { "name": "Enterprise Systems" },
            { "name": "Financial Cost and Management Accounting" },
            { "name": "Financial Management" },
            { "name": "Fundamentals of Computer Science" },
            { "name": "Fundamentals of Database Management System" },
            { "name": "Fundamentals of Economics" },
            { "name": "Fundamentals of Management" },
            { "name": "Human Resource Management" },
            { "name": "Image Processing and Pattern Recognition" },
            { "name": "Introduction to Innovation, IP, Management and Entrepreneurship" },
            { "name": "Introduction to IoT" },
            { "name": "Introduction to Machine Learning" },
            { "name": "Linear Algebra" },
            { "name": "Mobile Computing" },
            { "name": "Modern Web Applications" },
            { "name": "Object Oriented Programming" },
            { "name": "Operations Research" },
            { "name": "Operating Systems Concepts" },
            { "name": "Principle of Electrical Engineering" },
            { "name": "Principles of Electronics Engineering" },
            { "name": "Principles of Information Security" },
            { "name": "Psychology" },
            { "name": "Quantum Computation and Quantum Information" },
            { "name": "Robotics and Embedded Systems" },
            { "name": "Software Design with UML" },
            { "name": "Software Engineering Fundamentals" },
            { "name": "Statistical Methods" },
            { "name": "Physics for Computer Science" },
            { "name": "Formal Language and Automata Theory" }
        ]
    
    
};

export const resourceTypes = ['Research Paper', 'PYQ', 'Notes', 'Textbook', 'Lab Manual','Project','Other']
export const fileTypes = ['jpeg', 'jpg', 'png', 'webp', 'pdf', 'doc', 'docx', 'xls', 'xlsx', 'ppt', 'pptx']
export const semesters = ['1', '2', '3', '4', '5', '6', '7', '8', 'NA']
